/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

//	function

// for popup func
/*---------------------------------------------------*/
var popupFunc = function() {
	iScrolled = $(window).scrollTop();
	$(window).scrollTop(0);
	$('#container_all').css('position', 'fixed');
	$('#container_all').css('margin-top', '-'+iScrolled+'px');
	$('#layer-for-all-popup').show();
	$('#mask').slideDown();
};

// top menu for mobile
/*---------------------------------------------------*/
var mobileTopMenu = function() {
	var flag=0;
	var menu = $('.menu .menu-list');
	$('.mobile-menu-bt').click(function(){
		if (flag===0){
			$('.menu .menu-list').slideToggle();
			$('.mobile-menu-bt').addClass('icon-close');
			$('.mobile-menu-bt').removeClass('icon-menu-png');
			$('.header').addClass('active');
	    	flag=1;
		}
		else {
	    	$('.menu .menu-list').slideToggle();
			$('.mobile-menu-bt').removeClass('icon-close');
			$('.mobile-menu-bt').addClass('icon-menu-png');
			$('.header').removeClass('active');
			flag=0;
		}
		// если меню закрыть на моб экране, и затем экран увеличить, тогда очистим стили для меню, что бы оно стало видимsм
		$(window).resize(function(){
       		var w = $(window).width();
       		if(w > 767 && menu.is(':hidden')) {
       			menu.removeAttr('style');
       		}
   		});
	});
};

//переносим телефон и языки в меню
function phoneLangTop(){
	var widthwin = $(window).width();
	if(widthwin <= 480){
		$(".header .phone").appendTo(".menu-list");
		$(".lang-select").appendTo(".menu-list");
	}
	else if(widthwin > 480){
		$(".menu-list .lang-select").appendTo(".header .wrap");
		$(".menu-list .phone").appendTo(".header .wrap");
	}
}

// head inset page padding-left
/*---------------------------------------------------*/
function headInsetPading() {
	var widthwin = $(window).width();
	var widthwrap = $(".wrap").outerWidth();
	var rezult = (widthwin - widthwrap)/2+20;
	$('.inset-top-info').css('paddingLeft', rezult);
	if(widthwin <= 320){
		$('.inset-top-info').css('paddingLeft', 20);
	}
}

// contact width
/*---------------------------------------------------*/
function contactPageWidth() {
	var heightWin = $(window).height();
	var widthwin = $(window).width();
	var widthwrap = $(".wrap").outerWidth();
	var widthemployee = $(".employee-box").outerHeight();
	var rezultH = (heightWin - 60 - 90);
	var rezultp = ((widthwin - widthwrap)/2+10);
	var rezultH2 = ((heightWin - 60 - 90)/2);
	var rezulttop = ((rezultH2 - widthemployee)/2);
	if(widthwin > 767){
		$('.contact-left-col').css('min-height', rezultH);
		$('.contact-left-col').css('padding-left', rezultp);
		$('.contact-left-col').css('padding-top', rezulttop);
		$('.col-vh-50').css('min-height', rezultH2);
		$('.col-vh-50').css('padding-right', rezultp);
		$('.employee-box').css('padding-top', rezulttop);
	}
	else{}
}


function servicesMenuWidth() {
	var widthwrap = $(".wrap").outerWidth();
	var widthwin = $(window).width();
    var count_h = $('.head-tabs').children().length;
	  var ravno_h = (widthwrap-20) / count_h;

	if(widthwin > 900){
		$('.head-tabs li').css('width', ravno_h);
	}
	else if(widthwin <= 900 && widthwin > 480){
		$('.head-tabs li').css('width', '33%');
	}
	else if(widthwin <= 480){
		$('.head-tabs li').css('width', '50%');
	}
}


$(document).ready(function(){
// оределим размеры блоков глобально, используем дальше в ф-циях
	var widthwin = $(window).width();				// ширина окна
	var widthwrap = $(".wrap").outerWidth();		// ширина wrap
	var heightWin = $(window).height();				// высота окна



// top menu for mobile
	mobileTopMenu();

// owlCarousel main page
	$('#owl-carousel-1').owlCarousel({
	    loop:true,
	    margin:10,
	    nav:true,
	    dots:true,
	    autoHeight: true,
	    navText:[""],
	    responsive:{
	        0:{
	            items:1
	        },
	        767:{
	            items:1
	        },
	        992:{
	            items:2
	        }
	    }
	});
// owlCarousel rew programm
	$('#owl-carousel-2').owlCarousel({
	    loop:false,
	    margin:10,
	    nav:true,
	    dots:true,
	    navText:[""],
	    responsive:{
	        320:{
	            items:2
	        },
	        480:{
	            items:4
	        },
	        767:{
	            items:6
	        },
	        992:{
	            items:8
	        }
	    }
	});
// owlCarousel thank
	$('#owl-carousel-3').owlCarousel({
	    loop:true,
	    margin:10,
	    nav:true,
	    dots:true,
	    navText:[""],
	    responsive:{
	        320:{
	            items:2
	        },
	        480:{
	            items:3
	        },
	        767:{
	            items:4
	        },
	        992:{
	            items:6
	        }
	    }
	});
// section-1 main page height
	$(function() {
		var rezult = (heightWin - 244);
		if(heightWin > 800){
			$('.section-1').css('min-height', rezult);
		}
		else if(heightWin < 800 && heightWin > 500){
			$('.section-1').css('height', 'auto');
		}
		else if(heightWin < 500){
		/*	$('.section-1').css('min-height', 0);
			$('.services-box').css('position', 'inherit');
			$('.services-box').css('padding-bottom', 90); */
		}

	});

// main-info section-1 main page height
	$(function() {

		if(heightWin > 500){
			var rezult = (heightWin - 244 - 226);
			$('.main-info').css('height', rezult);
		}
		else {
			$('.main-info').css('height', 'auto');
		}
	});

// video bg main page
	$(function() {
		var rezult = (heightWin - 244);
		var sectionHeight = $(".section-1").outerHeight();
		if(widthwin > 767 && heightWin > 800){
			$('.section-video').css('min-height', rezult);
		}
		else if(widthwin > 767 && heightWin < 800 && heightWin > 500){
			$('.section-video').css('height', '100vh');
		}
		else if(widthwin > 767 && heightWin < 500){
			$('.section-video').css('height', sectionHeight);
		}
		else{}
        $(".player").YTPlayer();
	});

// smooth scrolling at anchor
	$(".scrollto").click(function () {
		var elementClick = $(this).attr("href");
		var destination = $(elementClick).offset().top;
		jQuery("html:not(:animated),body:not(:animated)").animate({scrollTop: destination-60}, 800);
	    return false;
	});

	$(".tab-bt-over a").click(function () {
		var destination = $('.nav-tabs').offset().top;
		jQuery("html:not(:animated),body:not(:animated)").animate({scrollTop: destination-70}, 800);
	});

  // link to tab
  $(function () {
      var hash = window.location.hash;
      if(hash) {
        $('ul.nav a[href="' + hash + '"]').tab('show');
        var destination = $('.nav-tabs').offset().top;
    		jQuery("html:not(:animated),body:not(:animated)").animate({scrollTop: destination-70}, 800);
      }

      // Change hash for page-reload
      $('ul.head-tabs a').on('shown.bs.tab', function (e) {
        if(history.pushState) {
            history.pushState(null, null, e.target.hash);
        } else {
            window.location.hash = e.target.hash; //Polyfill for old browsers
        }
      });
  });

// toss in the number of tabs
	$(window).width(function(){
		var widthwin = $(window).width();
		if(widthwin < 992) {
			$('.tab-bt-over').each(function(){
				$(this).find('.tab-bt').append($(this).find('.kvo'));
			});
	    }
	});

/*-------------------------------*/
// height price box = trailer img left 6_uslugi_price
	$(function() {
		var widthwin = $(window).width();
		if(widthwin > 767){
			var uslugaTrailer = $(".usluga-price-box-info .usluga-trailer").outerHeight();
			$('.usluga-price-box-info .top-meta').css('height', uslugaTrailer);
		}
	});

// head inset page padding-left
/*---------------------------------------------------*/
	headInsetPading();
	$(window).resize(headInsetPading);

// contact width
/*---------------------------------------------------*/
	contactPageWidth();
	$(window).resize(contactPageWidth);

// popups start
/*---------------------------------------------------*/


/* call back
---------------------------------------------------------*/
	$('.for-open-call-back-popup').click(function(e){
		var iScrolled = 0;
		popupFunc();
		$('.call-back-popup').slideDown();
		$('.addons-popup').slideUp();
	});
/* email
---------------------------------------------------------*/
	$('.for-open-email-us-popup').click(function(e){
		var iScrolled = 0;
		popupFunc();
		$('.email-us-popup').slideDown();
	});
/* thanks
---------------------------------------------------------*/
	$('.for-open-thanks-popup').click(function(e){
		var iScrolled = 0;
		popupFunc();
		$('.thanks-popup').slideDown();
	});
/* order-your-show-popup
---------------------------------------------------------*/
	$('.for-open-order-your-show-popup').click(function(e){
		var iScrolled = 0;
		popupFunc();
		$('.order-your-show-popup').slideDown();
	});
/* order-popup
---------------------------------------------------------*/
	$('.gw-go-btn').click(function(e){
		var iScrolled = 0;
		popupFunc();
    var showtitle = $('.max-wi h1').text();
    $('.order-popup .title').text(showtitle);
    $('.order-popup .order-showtitle input').val(showtitle);
  //  var orderpack = $(this).attr('href').replace(/^.*?#/,'');
    var orderpack = $(this).parents('.gw-go-col-inner').find('.gw-go-header').text();
    $('.order-popup .order-pack input').val(orderpack).attr('disabled', 'disabled');
		$('.order-popup').slideDown();

	});
/* addons-popup
---------------------------------------------------------*/
	$('.for-open-addons-popup').click(function(e){
		var iScrolled = 0;
		popupFunc();
		$('.addons-popup').slideDown();
	});
/* trailer-popup
---------------------------------------------------------*/
	$('.for-open-trailer-popup').click(function(e){
		var iScrolled = 0;
        var ytbVideo = $(this).data('ytb');
        ytbVideo = ytbVideo.replace("watch?v=", "v/");
		popupFunc();
		$('.trailer-popup').slideDown();
		$('#popup_video_block').append('<iframe src="' + ytbVideo + '?autoplay=1&rel=0" allowfullscreen="" frameborder="0" height="431" width="100%"></iframe>');
	});

  /* lightbox-popup
  ---------------------------------------------------------*/
  	$('.lightbox').off( 'click' ).on( 'click', function(e){
      /** Prevent Default Behaviour */
      e.preventDefault();
  		var iScrolled = 0;
      var lightid = $(this).data('id');
  		popupFunc();
  		$('.lightbox-popup').slideDown();

      /** Get Post ID */
      var post_id = $(this).attr( 'rel' );
      /** Ajax Call */
      $.ajax({
        cache: false,
        timeout: 8000,
        url: php_array.admin_ajax,
        type: "POST",
        data: ({ action:'theme_post_example', id:post_id }),
        beforeSend: function() {
            $( '#owl-carousel-4' ).html( 'Загрузка...' );
        },
        success: function( data, textStatus, jqXHR ){
            var $ajax_response = $( data );
            $( '#owl-carousel-4' ).html( $ajax_response );
            $("#owl-carousel-4").trigger("destroy.owl.carousel");
            // owlCarousel lightbox
            	$('#owl-carousel-4').owlCarousel({
            	    loop:true,
                  autoPlay: true,
                  singleItem: true,
            	    nav:true,
            	    dots:true,
            	    navText:[""],
            	    responsive:{
            	        320:{
            	            items:1
            	        },
            	        480:{
            	            items:1
            	        },
            	        767:{
            	            items:1
            	        },
            	        992:{
            	            items:1
            	        }
            	    }
            	});
          $("#owl-carousel-4").trigger("to.owl.carousel", [lightid, 200, true]);
        },
        error: function( jqXHR, textStatus, errorThrown ){
            console.log( 'The following error occured: ' + textStatus, errorThrown );
        },
        complete: function( jqXHR, textStatus ){
        }
      });
  	});
// close all popup
	$('body').on('click', '#mask, .icon-close', function() {
		$('#layer-for-all-popup').slideUp();
		$('#container_all').css('margin-top', '0px');
		$('#container_all').css('position', 'absolute');
		$(window).scrollTop(iScrolled);
		// $(".wrapper").unwrap("<div id='container_all'></div>");
		$('.call-back-popup').slideUp();
		$('.email-us-popup').slideUp();
		$('.thanks-popup').slideUp();
		$('.order-your-show-popup').slideUp();
		$('.order-popup').slideUp();
		$('.addons-popup').slideUp();
		$('.trailer-popup').slideUp();
		$('.lightbox-popup').slideUp();
		$('#popup_video_block').empty();
  //  $('#popup_gallery_block').empty();
		// $('body').removeClass('fixed');
		$('#mask').slideUp();
	});

// table cloned page programm-one
$('.price.old').parents('li').css('border-bottom', 'none');

//переносим телефон и языки в меню
	phoneLangTop();
	$(window).resize(phoneLangTop);

	servicesMenuWidth();
	$(window).resize(servicesMenuWidth);


	if ( typeof tian_ajax_params === 'undefined' ) {
		return false;
	}
	if ( tian_ajax_params.ajax_cat_preff ) {
      var tian_cat = tian_ajax_params.ajax_cat_preff;
      $.each(tian_cat, function(){
        var best_tab = $('#' + tian_cat);
        var e_tabs = $(best_tab).children('.section-tabs');
        var count_s = e_tabs.children().length;
        var ravno_s = (widthwrap-20) / count_s;
        if(widthwin > 900){
              $('.section-tabs li').css('width', ravno_s);
        }
        else if(widthwin <= 900 && widthwin > 480){
              $('.section-tabs li').css('width', '33%');
        }
        else if(widthwin <= 480){
              $('.section-tabs li').css('width', '50%');
        }

      });
    }

});
/* finish dom load
------------------------------------------------------------------------*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
